.giftCard-banner-container {
	margin: 80px 0px;
	position: relative;
	height: 424px;
	background: #F8F7F5;
}

.giftCard-image{
    width: 52%;
    position: absolute;
    top: -2.5rem;
    right: 0;
	@media #{$breakpoint-above-tablet}{
		height: 504px;
	}
	@media #{$breakpoint-desktop-small}{
		width: 60%;
	}
	@media #{$breakpoint-samsung-galaxy-tab-s7-landscape}{
		width: 60%;
	}
}
.giftCard-text-section{
    width:45%;
    padding-top: 56px;
		@media #{$breakpoint-medium-desktop} {
			padding-left: 1.15%;
		}
}
.smallTxtGiftCard {
	font-family: $National2Condensed;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 2px;
	text-transform: $uppercase;
	color: $Brand-Black;
	margin-bottom: 10px;
	left:15.9%;
	top:21.7%;
}

.largeTextGiftCard {
	font-family: $National2Condensed;
	font-style: normal;
	font-weight: 700;
	font-size: 64px;
	letter-spacing: 1px;
	text-transform: $uppercase;
	color: $Brand-Black;
	line-height: 64px;
	margin-bottom: 30px;
    padding-right: 70px
}

.giftCard-description-section{
    font-family: $National2Regular;
    font-size:16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #626365;
    padding-right: 160px;
    

}
@media #{$medium-only} {
	.lto-text-section {
		left: 10%;
	}
}
@media #{$breakpoint-between-mob-tablet} {
	.smallTxtLTO {
		font-size: 16px;
	}
	.largeTextLTO {
		font-size: 45px;
		width: 280px;
	}
}

@media #{$mobile-only} {	
	.giftCard-banner-container {
		margin: 40px 20px;
		position: relative;
		height: 581px;
		width: auto;
    	overflow-x: hidden;
		display: flex;
	}
	.giftCard-text-section {	
		top: 60px;
        padding-top: 300px;
		width:100%
	}
	.smallTxtGiftCard {
		height: 20px;
		width: 310px;
		left: 20px;
		top: 60px;
		border-radius: nullpx;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
		letter-spacing: 2px;
		padding-left : 20px;
	}
	.largeTextGiftCard {
		height: 92px;
		width:90%;
		left: 20px;
		top: 90px;
		border-radius: nullpx;
		font-size: 42px;
		font-style: normal;
		font-weight: 700;
		line-height: 42px;
		letter-spacing: 1px;
		text-align: left;
		margin-bottom: 20px;
        padding-right: 0px;
		padding-left : 20px;
	}
	.largeTextGiftCard-safari{
		width:100%;
	}
	.giftCard-image {
		width: 89.5%;
        height: 275px;
        top: 0%;
		margin: 10px 30px 20px 10px;
		right: unset;
    }
    .giftCard-description-section{
        font-family: $National2Regular;
        font-size:14px;
        line-height: 21px;
        letter-spacing: -0.1px;
		padding-left: 20px;
		padding-right: 0px;
		width:84%;
    }
    .giftCard-button-container{
         padding-left: 75px;
         margin-top: 30px;
		 @media #{$breakpoint-below-tablet} {
			min-width: 16.6rem !important;
		}
    }

}
@media #{$breakpoint-between-mob-tablet} {
	.giftCard-banner-container {
		padding-left: 1rem;
		margin: 40px 20px;
		position: relative;
		height: 350px;
		width: auto;
    	overflow-x: hidden;
		display: flex;
	}
	.giftCard-text-section {	
		margin-left: 20px;
		top: 60px;
	}
	.smallTxtGiftCard {
		height: 20px;
		width: 310px;
		left: 20px;
		top: 60px;
		border-radius: nullpx;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
		letter-spacing: 2px;
	}
	.largeTextGiftCard {
		height: 80px;
		width: 315px;
		left: 20px;
		top: 90px;
		border-radius: nullpx;
		font-size: 35px;
		font-style: normal;
		font-weight: 700;
		line-height: 40px;
		letter-spacing: 1px;
		text-align: left;
		margin-bottom: 20px;
	}
	.giftCard-image {
		width: 89.5%;
        height: 325px;
        top: 0%;
		margin: 10px 20px 30px 200px;
		right: unset;
 }
  .giftCard-description-section{
    padding-right: 5px;
}
}
